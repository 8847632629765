// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-antipasto-js": () => import("../src/pages/antipasto.js" /* webpackChunkName: "component---src-pages-antipasto-js" */),
  "component---src-pages-cheeses-js": () => import("../src/pages/cheeses.js" /* webpackChunkName: "component---src-pages-cheeses-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dips-js": () => import("../src/pages/dips.js" /* webpackChunkName: "component---src-pages-dips-js" */),
  "component---src-pages-distributors-js": () => import("../src/pages/distributors.js" /* webpackChunkName: "component---src-pages-distributors-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-js": () => import("../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-organic-coconut-yoghurt-js": () => import("../src/pages/organic-coconut-yoghurt.js" /* webpackChunkName: "component---src-pages-organic-coconut-yoghurt-js" */),
  "component---src-pages-potset-js": () => import("../src/pages/potset.js" /* webpackChunkName: "component---src-pages-potset-js" */),
  "component---src-pages-sauces-js": () => import("../src/pages/sauces.js" /* webpackChunkName: "component---src-pages-sauces-js" */),
  "component---src-pages-spoon-in-lid-js": () => import("../src/pages/spoon-in-lid.js" /* webpackChunkName: "component---src-pages-spoon-in-lid-js" */),
  "component---src-pages-yoghurts-js": () => import("../src/pages/yoghurts.js" /* webpackChunkName: "component---src-pages-yoghurts-js" */)
}

